



































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { CardCheckProps } from '../../../../../dsl/molecules/CardCheck'
import { ProductData } from '../../../../../contexts'
import { Loader } from '../../../../shared/molecules/Loader'

import { toImageProps } from '../../../../shared/support'

import { SingleCartItem } from '../../../organisms/SingleCartItem'
import { translateToCartItemEnhanced } from '../../../molecules/CartItem/CartItem.helpers'
import { Inject } from '../../../../../support'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ExtraItemsPicker>({
  name: 'ExtraItemsPicker',
  components: {
    Loader,
    SingleCartItem
  },
  created () {
    /**
     * @inheritDoc
     */
    this.composeItemCards(this.items)
  }
})
export class ExtraItemsPicker extends Vue {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Prop({ type: Array, required: true })
  public items!: Array<ProductData>

  public composedItemCards: CardCheckProps | null = null
  public giftResign: string = ''
  public selectedItem: ProductData | null = null
  public isLoading: boolean = false

  public get itemCards (): CardCheckProps | null {
    return this.composedItemCards
  }

  public toImageProps = toImageProps

  @Watch('items')
  protected onItemsUpdate () {
    this.composeItemCards(this.items)
  }

  /**
   * Resign from gift by deselecting all previously selected gifts
   * @param value
   */
  public onGiftResign (value: string): void {
    this.giftResign = value
    this.selectedItem = null

    this.$emit('resignFromItem')
  }

  /**
   * Update selected gift value
   * @param value
   */
  public onGiftSelect (value: ProductData | null): void {
    this.selectedItem = value
    this.giftResign = ''

    this.$emit('selectItem', { product: value })
  }

  protected composeItemCards (items: Array<ProductData>): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.composedItemCards = items.map((item: ProductData, index: number) => {
      return {
        isDisabled: false,
        id: item.id.toString(),
        multiple: false,
        name: `item-${index}`,
        required: false,
        value: item,
        data: { ...item }
      }
    })
  }

  public translateToCartItem = translateToCartItemEnhanced
}

export default ExtraItemsPicker
